import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';

import { sites as sitesConfig } from '@marketmuse/config/configs';
import {
  stripUrl,
  serializeAccessibleInventories,
} from '@marketmuse/utilities';

import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';
import useSiteChange from '../../../hooks/useSiteChange';
import useLogout from '../../../hooks/auth/useLogout';
import useGhostMode from '../../../hooks/auth/useGhostMode';
import useIdentitySuite from '../../../hooks/auth/useIdentitySuite';

import getSignInPathDefault from '../../../utils/getSignInPathDefault';
import isAdminMode, { isGhosting } from '../../../utils/isAdminMode';

import * as miscActions from '../../../actions/miscActions';
import * as adminActions from '../../../actions/adminActions';

import SimpleMenu from '../../SimpleMenu/SimpleMenu';
import MenuItemSeparator from '../../SimpleMenu/styles/MenuItemSeparator';

export const SiteLevel = styled.span`
  ${p => p.theme.type.textSmall}
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.colors.white};
`;

const SimpleMenuUserProfile = ({
  origin,
  showSidebarCollapse,
  children,
  ...props
}) => {
  const navigate = useSuiteNavigate();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const admin = useSelector(state => state.admin);
  const sites = useSelector(state => state.user?.org?.sites) || [];
  const developerMode = useSelector(state => state.persist?.developerMode);

  const { isSuperUser, orgSlug, siteId, userId } = useIdentitySuite();
  const { disableGhostMode } = useGhostMode();
  const handleLogout = useLogout();
  const setSite = useSiteChange({ siteId, slug: orgSlug, resetApps: true });

  const isAdmin = isAdminMode(admin, user);
  const isGhost = isGhosting(admin, user);
  const isDeveloper = isAdmin && developerMode;

  const inventories = useMemo(
    () => serializeAccessibleInventories(sites),
    [sites],
  );

  const items = [
    ...(inventories.length > 1
      ? [
          {
            title: 'Inventories',
            items: inventories.map(site => ({
              title: stripUrl(site.title),
              key: site.id,
              itemRight: (
                <SiteLevel>
                  {get(sitesConfig, `['${site.level}'].title`)}
                </SiteLevel>
              ),
              onClick: () => {
                const path = getSignInPathDefault(site.level);

                setSite({ siteId: site.id, slug: orgSlug, path });
              },
            })),
          },
          {
            component: MenuItemSeparator,
          },
        ]
      : []),
    ...(isSuperUser
      ? [
          {
            title: `${isAdmin ? 'Disable' : 'Enable'} Admin Mode`,
            onClick: e => {
              dispatch(
                miscActions.setSimple({
                  key: 'developerMode',
                  value: false,
                  persist: true,
                }),
              );
              if (isAdmin) {
                dispatch(adminActions.disableAdminMode());
              } else {
                dispatch(adminActions.adminModeEnable({ user: user }));
              }
            },
          },
        ]
      : []),
    ...(isSuperUser && isAdmin
      ? [
          {
            title: `${isDeveloper ? 'Disable' : 'Enable'} Developer Mode`,
            onClick: e => {
              dispatch(
                miscActions.setSimple({
                  key: 'developerMode',
                  value: !isDeveloper,
                  persist: true,
                }),
              );
            },
          },
        ]
      : []),
    ...(isGhost
      ? [
          {
            title: 'Switch back to your account',
            onClick: e => {
              disableGhostMode();
            },
          },
        ]
      : []),
    {
      title: 'Settings',
      onClick: e => {
        navigate('settings/account', { omitSiteId: true });
      },
    },
    {
      component: MenuItemSeparator,
    },
    {
      title: 'Sign Out',
      onClick: () => {
        handleLogout();
      },
    },
  ].filter(Boolean);

  const unAuthorizedMenuItems = [
    {
      title: 'Login',
      onClick: () => {
        handleLogout();
      },
    },
  ];

  return (
    <SimpleMenu
      label="User menu"
      items={userId ? items : unAuthorizedMenuItems}
      origin={origin}
      {...props}
    >
      {children}
    </SimpleMenu>
  );
};

SimpleMenuUserProfile.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'auto',
    'auto-start',
    'auto-end',
  ]),
  offset: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

export default SimpleMenuUserProfile;
