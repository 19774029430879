import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const saveOrgData = payload => ({
  type: ACTION_TYPES.SAVE_ORG_DATA,
  payload,
});

export const updateUserDataState = data => ({
  type: ACTION_TYPES.UPDATE_USER_DATA_STATE,
  payload: data,
});

export const getUserData = ({ callback, saveUserData }) => ({
  type: types.GET_USER_DATA,
  saveUserData,
  callback,
});

export const setUserAndSite = ({ user, site, track = true }) => ({
  type: types.SET_USER_IDENTITY,
  user,
  site,
  track,
});

export const saveUserData = user => ({
  type: types.SAVE_USER_DATA,
  user,
});

export const signOut = ({ redirect } = {}) => ({
  type: ACTION_TYPES.SIGN_OUT,
  payload: {
    redirect,
  },
});

export const updateOrgData = ({ fields, orgId = '', keys = [], callback }) => ({
  type: types.UPDATE_ORG_DATA,
  keys,
  orgId,
  fields,
  callback,
});

export const updateUserData = ({
  userId,
  fields,
  preventSuccessToast,
  keys,
  callback,
}) => ({
  type: ACTION_TYPES.UPDATE_USER_DATA,
  preventSuccessToast,
  userId,
  fields,
  callback,
  keys,
});

export const deleteUser = ({ userId = '', preventSuccessToast, callback }) => {
  return {
    type: types.DELETE_USER,
    userId,
    preventSuccessToast,
    callback,
  };
};
