import { Navigate } from 'react-router-dom';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { serializeAccessibleInventories } from '@marketmuse/utilities';

import getSignInPathDefault from '../../utils/getSignInPathDefault';

export const SiteCatchall = () => {
  const sites = useStoreSelector(state => state?.user.org.sites);
  const site = serializeAccessibleInventories(sites)[0];

  return <Navigate to={getSignInPathDefault(site.level)} />;
};
