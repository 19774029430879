import { twMerge } from 'tailwind-merge';
import { SubscriptionPlanType } from '@marketmuse/config/types/papi';
import { Text, Box, toast, Button } from '@marketmuse/components';
import {
  useSubscriptionUpdateMutation,
  useSubscriptionPlansQuery,
} from '@marketmuse/data-papi';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { ACTION_TYPES, MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import { miscActions } from '@marketmuse/data-state/misc';
import { paymentActions } from '@marketmuse/data-state/payment';
import { permissionsActions } from '@marketmuse/data-state/permissions';
import { errorsToString } from '@marketmuse/utilities';

export function SubscriptionPackages({
  cardClassNames: _cardClassNames,
  dataTags,
  subscriptionId,
  siteId,
  wrapperClassNames,
}: {
  cardClassNames?: Array<string>;
  dataTags: {
    ctaUpgrade: Record<string, string>;
    ctaDowngrade: Record<string, string>;
  };
  subscriptionId: string;
  siteId: string;
  wrapperClassNames?: Array<string>;
}) {
  const dispatch = useStoreDispatch();
  const [subscriptionUpdate, { loading }] = useSubscriptionUpdateMutation();

  const { data: plansData, loading: plansLoading } = useSubscriptionPlansQuery({
    variables: {
      limit: 20,
      page: 0,
      filters: [
        {
          typesIn: [SubscriptionPlanType.FREE],
        },
      ],
      sort: [],
    },
  });

  const onUpgrade = () => {
    dispatch(
      miscActions.setSimple({
        key: MISC_SIMPLE_KEYS.subscriptionManagement,
        value: true,
      }),
    );
  };

  const onDowngrade = async () => {
    const subscriptionPlanId = plansData?.subscriptionPlans?.items.find(
      plan => plan.type === SubscriptionPlanType.FREE,
    )?.id;
    const subscriptionRes = await subscriptionUpdate({
      variables: {
        subscriptionId: subscriptionId || '',
        subscriptionPlanId: subscriptionPlanId,
      },
    });

    if (subscriptionRes.data?.subscriptionUpdate) {
      const { subscription, org, permissions } =
        subscriptionRes?.data?.subscriptionUpdate || {};

      dispatch(paymentActions.setSubscription(subscription));
      dispatch({
        type: ACTION_TYPES.SAVE_ORG_DATA,
        payload: org,
      });
      dispatch({
        type: ACTION_TYPES.AGGREGATE_STATS_GET,
        payload: siteId,
      });
      dispatch(permissionsActions.setPermissions(permissions));
    } else {
      const errorMessage = subscriptionRes.errors
        ? errorsToString(subscriptionRes.errors as unknown as Error[])
        : 'Something went wrong, and we were not able to complete the request';
      toast.error(errorMessage);
    }
  };

  const cardClassNames = twMerge([
    'shadow-lg',
    'rounded-lg',
    'flex',
    'flex-col',
    'items-start',
    'p-4',
    'gap-3',
    'border',
    'border-gray-50',
    ...(_cardClassNames || []),
  ]);

  return (
    <Box className={wrapperClassNames}>
      <Box className={cardClassNames}>
        <Text className={['font-bold', 'text-base']}>Upgrade</Text>
        <Box as="ul" className="text-sm list-disc pl-4">
          <Text as="li">
            Choose between 3 packages to suit your content needs
          </Text>
          <Text as="li">Add Inventories</Text>
          <Text as="li">Monthly data updates</Text>
          <Text as="li">Export and collaborate</Text>
        </Box>
        <Button
          variant="blue400"
          disabled={loading}
          onClick={() => {
            onUpgrade();
          }}
          {...dataTags.ctaUpgrade}
        >
          Upgrade now
        </Button>
      </Box>
      <Box className={cardClassNames}>
        <Text className={['font-bold', 'text-base']}>Free</Text>
        <Box as="ul" className="text-sm list-disc pl-4">
          <Text as="li">Use Optimize or Research 10 times a month</Text>
          <Text as="li">No Inventory</Text>
          <Text as="li">No exports</Text>
          <Text as="li">No team access</Text>
        </Box>
        <Button
          variant="blue200"
          disabled={plansLoading || loading}
          onClick={() => onDowngrade()}
          {...dataTags.ctaDowngrade}
        >
          Downgrade to free
        </Button>
      </Box>
    </Box>
  );
}
